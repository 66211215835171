import { useEffect } from "react";
import { useParams } from "react-router-dom";
import CustomerDetailsComponent, { CustomerDetailsTab } from "../../../../components/customer-details/customer-details.component";
import { DataSourceEnum } from "../../../../enums/data-source.enum";
import { PanelType } from "../../../../enums/panel-type.enum";
import { useStore } from "../../../../hooks/store.hook";
import { CustomerType } from "../../../../types/customer.type";
import { getRequest } from "../../../../utils/http-requests";

const PanelPage = () => {
    let params = useParams();
    let ignore = false;
    const uiStore = useStore('uiStore');
    
    useEffect(() => {
        if (!ignore) {
            const fetchUserByPhone = async() => {
                const { data } = await getRequest({
                    url: `/customer/get-customer-by-phone-web`,
                    queryParams: {
                        phoneNumber: params.phoneNumber
                    }
                });

                const customer : CustomerType = data;

                uiStore.openPanel({
                            key: PanelType.EditUser,
                            title: `${customer.fullName}`,
                            component: <CustomerDetailsComponent 
                                            customerId={customer.id} 
                                            tabFocused={CustomerDetailsTab.CustomerDetails}
                                            source={DataSourceEnum.Central}
                                        />,
                        });
            }
            fetchUserByPhone().catch(console.error);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            ignore = true;
        }
    }, []);    
    
    return (<></>)
}

export default PanelPage;