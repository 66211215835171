import { makeAutoObservable, observable } from "mobx";
import { UserType } from "../types/user.type";
import { RootStore } from "./root.store";
import envVars from '../config/env.config';
import { WebsocketTypeEnum } from "../enums/websocket-type.enum";
import { WebsocketType } from "../types/websocket.type";
import { PanelType } from "../enums/panel-type.enum";
import OrderPanelComponent from "../components/order-panel/order-panel.component";
import { CustomerType } from "../types/customer.type";
import { DataSourceEnum } from "../enums/data-source.enum";

export class WebsocketStore {

    private _rootStore: RootStore;

    user: UserType | null = null;
    ws?: WebSocket | undefined;

    constructor(rootStore: RootStore) {
        this._rootStore = rootStore;
        makeAutoObservable(this, {
            ws: observable.ref
        });

        if (envVars.apiBaseUrl.indexOf("localhost") == -1) {
            var url = envVars.apiBaseUrl.substring(envVars.apiBaseUrl.indexOf("//"));
            this.ws = new WebSocket(`wss:${url}`);
        } else {
            this.ws = new WebSocket(`ws:localhost:${envVars.port}`);
        }

        const user = this._rootStore.userStore.user;
        
        if (user) {
            this.sendMessage(user.id, WebsocketTypeEnum.UserId);
        }
        
        this.ws.addEventListener('message', (event: { data: any; }) => {
            const receivedData: WebsocketType = JSON.parse(event.data);
            
            if (receivedData.type === WebsocketTypeEnum.OpenModal) {
                const userData: CustomerType = JSON.parse(receivedData.message);
                this._rootStore.uiStore.openPanel({
                    key: PanelType.AddOrder,
                    component: <OrderPanelComponent customerDetail={userData} source={DataSourceEnum.Central} />,
                    panelWidth: '700px',
                    title: 'Creeaza o comanda'
                });
            }

            var parsedData:string = JSON.parse(receivedData.message);
            console.log('Message from server: ', parsedData);
        });
    }

    private _waitForSocketConnection(callback: { (): void; (): void; } | null){
        setTimeout(
            () => {
                if (this.ws?.readyState === 1) {
                    console.log("Connection is made")
                    if (callback != null){
                        callback();
                    }
                } else {
                    console.log("wait for connection...")
                    this._waitForSocketConnection(callback);
                }
            }, 100); // wait 100 milisecond for the connection...
    } 

    sendMessage(msg: string, type: WebsocketTypeEnum) {
        this._waitForSocketConnection(() => {
            var message : WebsocketType = {
                type: type,
                message: msg
            }
            this.ws?.send(JSON.stringify(message));
        });
    }

    getWS(): WebSocket | undefined {
        return this.ws;
    }

    setWS(newWS: WebSocket | undefined) {
        this.ws = newWS;
    }

    getRootStore(): RootStore {
        return this._rootStore;
    }
}