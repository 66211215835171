import { StorageEnum } from "../../../../enums/storage.enum";
import {  Navigate } from "react-router-dom";
import { useStore } from "../../../../hooks/store.hook";
import { WebsocketTypeEnum } from "../../../../enums/websocket-type.enum";

const LogoutPage = () => {
    const token = localStorage.getItem(StorageEnum.Token);
    const websocketStore = useStore('websocketStore');
    const ws = websocketStore.getWS();

    if (ws) {
        websocketStore.sendMessage("close", WebsocketTypeEnum.Close);
    }

    if (token) 
        localStorage.clear()
    return <><Navigate to="/auth/login" /></>
    
}

export default LogoutPage;