import { Navigate, RouteObject, useRoutes } from "react-router-dom"
import { RoleEnum } from "../../enums/role.enum";
import { getUser } from "../../utils/get-user.util";
import CustomersPage from "./pages/customers/customers.page";
import DashboardPage from "./pages/dashboard/dashboard.page";
import OrdersPage from "./pages/orders/orders.page";
import PanelPage from "./pages/panel/panel.page";
import ProductsPage from "./pages/products/products.page";
import ProfilePage from "./pages/profile/profile.page";
import SettingsPage from "./pages/settings/settings.page";
import UsersPage from "./pages/users/users.page";

type RawRouteType = {
    to: string;
    component: JSX.Element,
    roles?: RoleEnum[];
}

const MainRouter = () => {

    const routes: RawRouteType[] = [
        {
            to: 'dashboard',
            component: <DashboardPage />
        },
        {
            to: 'users',
            roles:[RoleEnum.Admin],
            component: <UsersPage />
        },
        {
            to: 'profile',
            component: <ProfilePage />,
        },
        {
            to: 'customers',
            component: <CustomersPage />
        },
        {
            to: 'orders',
            component: <OrdersPage/>
        },
        {
            to: 'products',
            component: <ProductsPage />
        },
        {
            to: 'settings',
            component: <SettingsPage />
        },
        {
            to: 'profile/:userId',
            component: <ProfilePage />,
        },
        {
            to: 'orders/search/:phoneNumber',
            component: <PanelPage />,
        },
    ]

    const user = getUser();
    let parsedRoutes: RouteObject[] = [];

    if (user) {
        parsedRoutes = routes
            .filter(route => {
                if (!route.roles) return true;
                return route.roles.every((requiredRole) => user.roles.includes(requiredRole));
            })
            .map(route => ({
                element: route.component,
                path: route.to
            }));

        if (parsedRoutes.length > 0) {
            parsedRoutes = [
                ...parsedRoutes,
                {
                    path: '*',
                    element: <Navigate to={parsedRoutes[0].path ?? ''} />
                }
            ];
        }
            
    }

    let element = useRoutes(parsedRoutes);

    return element;

}

export default MainRouter;