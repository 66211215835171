import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { RoleEnum } from "../../enums/role.enum";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { useStore } from "../../hooks/store.hook";
import { StateFieldType } from "../../types/form.types";
import { CreateUserType, UpdateUserType } from "../../types/user.type";
import { createUser, getUser, updateUser } from "../../utils/requests";
import ButtonComponent from "../button/button.component";
import FormSectionComponent from "../form-section/form-section.component";
import RoleComponent from "../role/role.component";
import TextFieldComponent from "../text-field/text-field.component";
import UserPanelComponentStyled from "./user-panel.component.styled";


type StateType = {
    fields: {
        email: StateFieldType<string>;
        firstName: StateFieldType<string>;
        lastName: StateFieldType<string>;
        roles: StateFieldType<RoleEnum[]>;
        ext: StateFieldType<string>;
    };
    shouldDisplayError: boolean;
}

interface UserPanelComponentPropsType {
    userId?: string;
}

const UserPanelComponent = observer(({userId}:UserPanelComponentPropsType) => {

    /** inject mobx store inside  */
    const uiStore = useStore('uiStore');
    const tableStore = useStore('tableStore');

    const [state, setState] = useState<StateType>({
        fields: {
            email: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati o adresa de email valida'
            },
            firstName: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un prenume valid'
            },
            lastName: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un nume valid'
            },
            roles: {
                value: [],
                isValid: false,
                errorMessage: 'Furnizati cel putin un rol pentru acest utilizator',
                validator: (newValue) => newValue.length > 0
            },
            ext: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati o valoare ext'
            },
        },
        shouldDisplayError: false
    });
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isLoading, setIsLoading] = useState(false);

    const updateStateField = useCallback(
        (currentField: StateFieldType<any>, newValue: any): StateFieldType<any> => {
            return {
                ...currentField,
                isValid: (currentField.validator ? (currentField.validator as any)(newValue) : !!newValue) || 
                    currentField.noValidation,
                value: newValue
            }
        },
        []
    )

    const updateState = useCallback(
        <T extends keyof typeof state.fields>(field: T, newValue: any) => {
            setState(state => ({
                ...state,
                fields: {
                    ...state.fields,
                    [field]: updateStateField(state.fields[field], newValue)
                }
            }))
        },
        [state, updateStateField]
    )

    useEffect(
        () => {
            
            if(state.fields.email.value
                || state.fields.firstName.value
                || state.fields.lastName.value
                || state.fields.roles.value.length
                || state.fields.ext.value
                )
                uiStore.setOrderData(true)
            else
                uiStore.setOrderData(false)

        },
        [
            state.fields.email.value, 
            state.fields.firstName.value, 
            state.fields.lastName.value, 
            state.fields.roles.value,
            state.fields.ext.value,
            uiStore
        ]
    )

    useEffect(
        () => {
            if (userId) {
                uiStore.updatePanel({ title: 'Editare utilizator' });

                getUser(userId)
                    .then(user => {
                        setState(state => ({
                            ...state,
                            fields: {
                                ...state.fields,
                                email: updateStateField(state.fields.email, user.email ?? ''),
                                firstName: updateStateField(state.fields.firstName, user.firstName ?? ''),
                                lastName: updateStateField(state.fields.lastName, user.lastName ?? ''),
                                roles: updateStateField(state.fields.roles, user.roles ?? ''),
                                ext: updateStateField(state.fields.ext, user.ext ?? '')
                            }
                        }))
                    })
                    .catch(e => toast.error(e.message));
            } else {
                uiStore.updatePanel({ title: 'Adaugare utilizator' });
            }
        },
        [uiStore, updateStateField, userId]
    )

    const isEdit = useMemo(
        (): boolean => {
            return !!userId;
        },
        [userId]
    )

    const onRoleDelete = useCallback(
        (role: RoleEnum) => {
            const newValue = state.fields.roles.value.filter(f => f !== role);
            updateState('roles', newValue);
        },
        [state, updateState]
    ) 

    const onRoleAdd = useCallback(
        (role: RoleEnum) => {
            const newValue = [...state.fields.roles.value, role];
            updateState('roles', newValue);
        } ,
        [state, updateState]
    ) 

    const handleClick = React.useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            if (!event?.currentTarget) return;
            setAnchorEl(() => event.currentTarget);
        },
        []
    );
    
    const handleClose = React.useCallback(
        () => {
            setAnchorEl(() =>null);
        },
        []
    );

    const getAvailableOptions = useCallback(
        (): RoleEnum[] => {
            var allRoles = Object.values(RoleEnum);
            return allRoles.filter( role => !state.fields.roles.value.includes(role) );
        },
        [state.fields.roles]
    )

    const userAction = useCallback(
        async () => {
            setState(state => ({
                ...state,
                shouldDisplayError: true
            }));

            const isNotValid = Object.values(state.fields).some(field => !field.isValid);
            if (isNotValid) return;

            var data: CreateUserType |  UpdateUserType

            if(userId)
            {
                data = {
                    firstName: state.fields.firstName.value,
                    lastName: state.fields.lastName.value,
                    roles: state.fields.roles.value,
                    ext: state.fields.ext.value,
                }
            }
            else 
            {
                data = {
                    email: state.fields.email.value,
                    firstName: state.fields.firstName.value,
                    lastName: state.fields.lastName.value,
                    roles: state.fields.roles.value,
                    ext: state.fields.ext.value,
                }
            }

            /** make the request to create the user */

            setIsLoading(() => true);
            try {
                if (userId) {
                    /** edit the user */
                    await updateUser(userId, data);
                } else {
                    /** create the User */
                    await createUser(data);
                }
                toast.success('Utilizatorul a fost salvat cu succes');
                uiStore.dismissPanel();
                tableStore.updateTable(TableTypeEnum.Users)
            } catch(e: any) {
                toast.error(e.message);
            }
            setIsLoading(() => false);

        },
        [state, uiStore, tableStore, userId]
    )


    /** define the return statement bellow */
    return (
        <UserPanelComponentStyled>

                <FormSectionComponent variant="panel" title="Detalii generale">
                    
                    <Grid container spacing={2}>
                        { isEdit ? '' : 
                            <Grid item xs={12}>
                                <TextFieldComponent
                                    label="Adresa de email" 
                                    variant="outlined" 
                                    fullWidth={true}
                                    value={state.fields.email.value}
                                    error={state.shouldDisplayError && !state.fields.email.isValid}
                                    helperText={state.shouldDisplayError && !state.fields.email.isValid && state.fields.email.errorMessage}
                                    onTextChange={e => updateState('email', e)}
                                />
                            </Grid>
                        }
                        
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Prenume" 
                                variant="outlined" 
                                fullWidth={true}
                                value={state.fields.firstName.value}
                                error={state.shouldDisplayError && !state.fields.firstName.isValid}
                                helperText={state.shouldDisplayError && !state.fields.firstName.isValid && state.fields.firstName.errorMessage}
                                onTextChange={e => updateState('firstName', e)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Nume" 
                                variant="outlined" 
                                fullWidth={true}
                                value={state.fields.lastName.value}
                                error={state.shouldDisplayError && !state.fields.lastName.isValid}
                                helperText={state.shouldDisplayError && !state.fields.lastName.isValid && state.fields.lastName.errorMessage}
                                onTextChange={e => updateState('lastName', e)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Ext" 
                                variant="outlined" 
                                fullWidth={true}
                                value={state.fields.ext.value}
                                error={state.shouldDisplayError && !state.fields.ext.isValid}
                                helperText={state.shouldDisplayError && !state.fields.ext.isValid && state.fields.ext.errorMessage}
                                onTextChange={e => updateState('ext', e)}
                            />
                        </Grid>
                    </Grid>

                </FormSectionComponent>

                <FormSectionComponent variant="panel" title="Roluri">
                    <RoleComponent 
                        anchorEl={anchorEl} 
                        getAvailableOptions={getAvailableOptions} 
                        handleClick={handleClick}
                        handleClose={handleClose}
                        onRoleAdd={onRoleAdd}
                        roles={state.fields.roles.value}
                        onRoleDelete={onRoleDelete}
                        isSelf={false}
                    />
                    {
                        state.shouldDisplayError && !state.fields.roles.isValid ?
                        <span className="roles-error-message">{state.fields.roles.errorMessage}</span> :
                        ''
                    }
                </FormSectionComponent>

                <div className="button-container">
                    <ButtonComponent onClick={userAction} isLoading={isLoading}>
                        { isEdit ? 'Salveaza' : 'Creeaza' }
                    </ButtonComponent>
                </div>
        </UserPanelComponentStyled>
    )

});

export default UserPanelComponent;