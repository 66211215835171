import { Box, Grid, InputAdornment } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { StateFieldType } from '../../types/form.types';
import { SettingsCompanyType } from '../../types/settings.type';
import ButtonComponent from '../button/button.component';
import FormSectionComponent from '../form-section/form-section.component';
import TextFieldComponent from '../text-field/text-field.component';
import CompanySettingsComponentStyled from './company-settings.component.styled';
import AsyncSelectComponent, { AsyncSelectOptionType } from '../async-select/async-select.component';
import { getRequest } from '../../utils/http-requests';
import { DictionaryType } from '../../types/dictionary.type';

type StateType = {
    fields: {
        name: StateFieldType<string>;
        vatCode: StateFieldType<string>;
        regCode: StateFieldType<string>;

        contactDetailsFirstName: StateFieldType<string>;
        contactDetailsLastName: StateFieldType<string>;
        contactDetailsEmail: StateFieldType<string>;
        contactDetailsPhoneNumber: StateFieldType<string>;
        
        addressCounty: StateFieldType<DictionaryType>;
        addressCity: StateFieldType<DictionaryType>;
        addressPostalCode: StateFieldType<string>;
        addressStreet: StateFieldType<string>;
        addressStreetNumber: StateFieldType<string>;
        addressFlat: StateFieldType<string>;
        addressFloor: StateFieldType<string>;
        addressBuilding: StateFieldType<string>;
        addressApartmentNumber: StateFieldType<string>;
    };
    shouldDisplayError: boolean;
}

export type CompanySettingsComponentPropsType = {
    companyDetails?: Partial<SettingsCompanyType>,
    isLoading: boolean,
    onSave: (updatedCompanyDetails: SettingsCompanyType) => void 
}

const CompanySettingsComponent = ({
    companyDetails,
    isLoading,
    onSave = () => {}
}: CompanySettingsComponentPropsType) => {

    const [state, setState] = useState<StateType>({
        fields: {
            name: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un nume al companiei valid'
            },
            vatCode: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un cod fiscal valid'
            },
            regCode: {
                value: '',
                isValid: true,
                noValidation: true
            },
            contactDetailsFirstName: {
                value: '',
                isValid: true,
                noValidation: true
            },
            contactDetailsLastName: {
                value: '',
                isValid: true,
                noValidation: true
            },
            contactDetailsEmail: {
                value: '',
                isValid: true,
                noValidation: true
            },
            contactDetailsPhoneNumber: {
                value: '',
                isValid: false,
                errorMessage: 'Introduceti un numar de telefon valid'
            },
            addressCounty: {
                value: { label: '' },
                isValid: false,
                
            },
            addressCity: {
                value: { label: '' },
                isValid: false,
                
            },
            addressPostalCode: {
                value: '',
                isValid: false,
                errorMessage: 'Introduceti un cod postal valid'
            },
            addressStreet: {
                value: '',
                isValid: false,
                errorMessage: 'Introduceti strada pe care se afla sediul companiei'
            },
            addressStreetNumber: {
                value: '',
                isValid: false,
                errorMessage: 'Introduceti numarul strazii'
            },
            addressFlat: {
                value: '',
                isValid: true,
                noValidation: true
            },
            addressFloor: {
                value: '',
                isValid: true,
                noValidation: true
            },
            addressBuilding: {
                value: '',
                isValid: true,
                noValidation: true
            },
            addressApartmentNumber: {
                value: '',
                isValid: true,
                noValidation: true
            },
        },
        shouldDisplayError: false
    })

    const [addressEdited, setAddresseEdited] = useState<boolean>(false);
    const [optionsCounties, setOptionsCounties] = useState<AsyncSelectOptionType[]>([]);
    const [optionsCity, setOptionsCity] = useState<AsyncSelectOptionType[]>([]);
    const [, setIsLoading] = useState<boolean>(false);

    const updateIsValid = useCallback(
        (currentField: StateFieldType<any>, newValue: any): StateFieldType<any> => {
            return {
                ...currentField,
                isValid: newValue,
            }
        },
        []
    )

    const optionIsValid = useCallback(
        (value: AsyncSelectOptionType, options: any[]) => {
            
            const indexValue = options.map( m => m.label === value?.label)
            const isNotValid = indexValue.some(field => field);
            
            return isNotValid
        },
        []
    )

    const updateStateField = useCallback(
        (currentField: StateFieldType<any>, newValue: any): StateFieldType<any> => {
            return {
                ...currentField,
                isValid: (currentField.validator ? (currentField.validator as any)(newValue) : !!newValue) || 
                    currentField.noValidation,
                value: newValue
            }
        },
        []
    )

    useEffect(
        () => {
            const urlCounties = "/dictionary/getcounties"
            async function getCounties(){
                setIsLoading(true)
                const response = await getRequest({url: urlCounties})
                setOptionsCounties(response.data)
                setIsLoading(false)
            }
            getCounties()
        },
        []
    )

    useEffect(
        () => {
            if(!state.fields.addressCounty.value?.label) return
            const urlCities = `/dictionary/getcities/${state.fields.addressCounty.value.label}`
            async function getCities(){
                setIsLoading(true)
                const response = await getRequest({url: urlCities})
                setOptionsCity(response.data)
                setIsLoading(false)
            }
            getCities()
        },
        [state.fields.addressCounty.value.label]
    )

    useEffect(
        () => {
            if(optionsCounties && optionsCity)
                setState((m: any) => ({
                    ...m,
                    fields: {
                        ...m.fields,
                        addressCounty: updateIsValid(m.fields.addressCounty, optionIsValid(m.fields.addressCounty.value, optionsCounties)),
                        addressCity: updateIsValid(m.fields.addressCity, optionIsValid(m.fields.addressCity.value, optionsCity))
                    }
                }))
        },
        [
            optionIsValid, 
            optionsCity, 
            optionsCounties, 
            updateIsValid
        ]
    )

    const updateState = useCallback(
        <T extends keyof typeof state.fields>(field: T, newValue: any) => {
            setState(state => ({
                ...state,
                fields: {
                    ...state.fields,
                    [field]: updateStateField(state.fields[field], newValue)
                }
            }))
        },
        [state, updateStateField]
    )

    const updateCompanyDetails = useCallback(
        () => {

            setState(() => ({
                ...state,
                shouldDisplayError: true
            }))

            const isNotValid = Object.values(state.fields).some(field => !field.isValid);
            if (isNotValid) return;

            const updatedCompanyDetails: SettingsCompanyType = {
                name: state.fields.name.value,
                vatCode: state.fields.vatCode.value,
                regCode: state.fields.regCode.value,
                contactDetails: {
                    firstName: state.fields.contactDetailsFirstName.value,
                    lastName: state.fields.contactDetailsLastName.value,
                    email: state.fields.contactDetailsEmail.value,
                    phoneNumber: state.fields.contactDetailsPhoneNumber.value,
                },
                address: {
                    city: state.fields.addressCity.value.label,
                    county: state.fields.addressCounty.value.label,
                    postalCode: state.fields.addressPostalCode.value,
                    street: state.fields.addressStreet.value,
                    streetNumber: state.fields.addressStreetNumber.value,
                    flat: state.fields.addressFlat.value,
                    floor: state.fields.addressFloor.value,
                    building: state.fields.addressBuilding.value,
                    apartmentNumber: state.fields.addressApartmentNumber.value,
                }
            }

            onSave(updatedCompanyDetails);
        },
        [onSave, state]
    )
    
    useEffect(
        () => {
            if (!companyDetails) return;
            setState(state => ({
                ...state,
                fields: {
                    name: updateStateField(state.fields.name, companyDetails.name ?? ''),
                    vatCode: updateStateField(state.fields.vatCode, companyDetails.vatCode ?? ''),
                    regCode: updateStateField(state.fields.regCode, companyDetails.regCode ?? ''),
                    contactDetailsFirstName: updateStateField(state.fields.contactDetailsFirstName, companyDetails.contactDetails?.firstName ?? ''),
                    contactDetailsLastName: updateStateField(state.fields.contactDetailsLastName, companyDetails.contactDetails?.lastName ?? ''),
                    contactDetailsEmail: updateStateField(state.fields.contactDetailsEmail, companyDetails.contactDetails?.email ?? ''),
                    contactDetailsPhoneNumber: updateStateField(state.fields.contactDetailsPhoneNumber, companyDetails.contactDetails?.phoneNumber ?? ''),
                    addressCity: updateStateField(state.fields.addressCity, { label: companyDetails.address?.city ?? '' }),
                    addressCounty: updateStateField(state.fields.addressCounty, { label: companyDetails.address?.county ?? '' }),
                    addressPostalCode: updateStateField(state.fields.addressPostalCode, companyDetails?.address?.postalCode ?? ''),
                    addressStreet: updateStateField(state.fields.addressStreet, companyDetails?.address?.street ?? ''),
                    addressStreetNumber: updateStateField(state.fields.addressStreetNumber, companyDetails?.address?.streetNumber ?? ''),
                    addressFlat: updateStateField(state.fields.addressFlat, companyDetails?.address?.flat ?? ''),
                    addressBuilding: updateStateField(state.fields.addressBuilding, companyDetails?.address?.floor ?? ''),
                    addressFloor: updateStateField(state.fields.addressFloor, companyDetails?.address?.building ?? ''),
                    addressApartmentNumber: updateStateField(state.fields.addressApartmentNumber, companyDetails?.address?.apartmentNumber ?? ''),
                    
                }
            }))

            if(addressEdited){
                updateCompanyDetails()
                setAddresseEdited( () => false)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [companyDetails, addressEdited]
    )

    /** define the return statement bellow */
    return (
        <CompanySettingsComponentStyled>
            <FormSectionComponent title="Detalii generale">
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextFieldComponent
                            label="Nume companie" 
                            variant="outlined" 
                            fullWidth={true}
                            value={state.fields.name.value || ''}
                            error={state.shouldDisplayError && !state.fields.name.isValid}
                            helperText={state.shouldDisplayError && !state.fields.name.isValid && state.fields.name.errorMessage}
                            onTextChange={e => updateState('name', e)}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextFieldComponent
                            label="Cod fiscal" 
                            variant="outlined" 
                            fullWidth={true}
                            value={state.fields.vatCode.value || ''}
                            error={state.shouldDisplayError && !state.fields.vatCode.isValid}
                            helperText={state.shouldDisplayError && !state.fields.vatCode.isValid && state.fields.vatCode.errorMessage}
                            onTextChange={e => updateState('vatCode', e)}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextFieldComponent
                            label="Numar Registrul Comertului" 
                            variant="outlined" 
                            fullWidth={true}
                            value={state.fields.regCode.value || ''}
                            error={state.shouldDisplayError && !state.fields.regCode.isValid}
                            helperText={state.shouldDisplayError && !state.fields.regCode.isValid && state.fields.regCode.errorMessage}
                            onTextChange={e => updateState('regCode', e)}
                        />
                    </Grid>
                </Grid>

                <div className="section-header">
                    <div className="section-title" >
                        <div className="title2">
                            {"Date de contact"}
                        </div>
                    </div>
                </div>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextFieldComponent
                            label="Prenume" 
                            variant="outlined" 
                            fullWidth={true}
                            value={state.fields.contactDetailsFirstName.value  || ''}
                            error={state.shouldDisplayError && !state.fields.contactDetailsFirstName.isValid}
                            helperText={state.shouldDisplayError && !state.fields.contactDetailsFirstName.isValid && state.fields.contactDetailsFirstName.errorMessage}
                            onTextChange={e => updateState('contactDetailsFirstName', e)}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextFieldComponent
                            label="Nume" 
                            variant="outlined" 
                            fullWidth={true}
                            value={state.fields.contactDetailsLastName.value || ''}
                            error={state.shouldDisplayError && !state.fields.contactDetailsLastName.isValid}
                            helperText={state.shouldDisplayError && !state.fields.contactDetailsLastName.isValid && state.fields.contactDetailsLastName.errorMessage}
                            onTextChange={e => updateState('contactDetailsLastName', e)}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextFieldComponent
                            label="Numar de telefon" 
                            variant="outlined" 
                            fullWidth={true}
                            value={state.fields.contactDetailsPhoneNumber.value || ''}
                            error={state.shouldDisplayError && !state.fields.contactDetailsPhoneNumber.isValid}
                            helperText={state.shouldDisplayError && !state.fields.contactDetailsPhoneNumber.isValid && state.fields.contactDetailsPhoneNumber.errorMessage}
                            onTextChange={e => updateState('contactDetailsPhoneNumber', e)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">+4</InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
                
                <div className="section-header">
                    <div className="section-title" >
                        <div className="title2">
                            {"Adresa"}
                        </div>
                    </div>
                </div>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <AsyncSelectComponent
                            label="Judet"
                            options={optionsCounties}
                            value={!state.fields.addressCounty.value?.label ? null : state.fields.addressCounty.value}
                            onOptionChange={e => { updateState('addressCounty', e); updateState('addressCity', null); }}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {[option.label]}
                                </Box>
                            )}
                            error={state.shouldDisplayError && !state.fields.addressCounty.isValid}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <AsyncSelectComponent
                            label="Oras"
                            options={optionsCity}
                            value={state.fields.addressCity.value}
                            onOptionChange={e => {updateState('addressCity', e)}}
                            isOptionEqualToValue={(option, value) => {
                                return option.label === value.label
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {[option.label]}
                                </Box>
                            )}
                            error={state.shouldDisplayError && !state.fields.addressCity.isValid}
                        />
                    </Grid>

                    <Grid item xs={9}>
                        <TextFieldComponent
                            label="Strada"
                            variant="outlined"
                            fullWidth={true}
                            value={state.fields.addressStreet.value}
                            error={state.shouldDisplayError && !state.fields.addressStreet.isValid}
                            helperText={state.shouldDisplayError && !state.fields.addressStreet.isValid && state.fields.addressStreet.errorMessage}
                            onTextChange={e => updateState('addressStreet', e)}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextFieldComponent
                            label="Nr."
                            variant="outlined"
                            fullWidth={true}
                            value={state.fields.addressStreetNumber.value}
                            error={state.shouldDisplayError && !state.fields.addressStreetNumber.isValid}
                            helperText={state.shouldDisplayError && !state.fields.addressStreetNumber.isValid && state.fields.addressStreetNumber.errorMessage}
                            onTextChange={e => updateState('addressStreetNumber', e)}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <TextFieldComponent
                            label="Bloc"
                            variant="outlined"
                            fullWidth={true}
                            value={state.fields.addressFlat.value}
                            error={state.shouldDisplayError && !state.fields.addressFlat.isValid}
                            helperText={state.shouldDisplayError && !state.fields.addressFlat.isValid && state.fields.addressFlat.errorMessage}
                            onTextChange={e => updateState('addressFlat', e)}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <TextFieldComponent
                            label="Scara"
                            variant="outlined"
                            fullWidth={true}
                            value={state.fields.addressBuilding.value}
                            error={!state.fields.addressBuilding.isValid}
                            helperText={!state.fields.addressBuilding.isValid && state.fields.addressBuilding.errorMessage}
                            onTextChange={e => updateState('addressBuilding', e)}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <TextFieldComponent
                            label="Etaj"
                            variant="outlined"
                            fullWidth={true}
                            value={state.fields.addressFloor.value}
                            error={!state.fields.addressFloor.isValid}
                            helperText={!state.fields.addressFloor.isValid && state.fields.addressFloor.errorMessage}
                            onTextChange={e => updateState('addressFloor', e)}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <TextFieldComponent
                            label="Nr. ap."
                            variant="outlined"
                            fullWidth={true}
                            value={state.fields.addressApartmentNumber.value}
                            error={!state.fields.addressApartmentNumber.isValid}
                            helperText={!state.fields.addressApartmentNumber.isValid && state.fields.addressApartmentNumber.errorMessage}
                            onTextChange={e => updateState('addressApartmentNumber', e)}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextFieldComponent
                            label="Cod Postal"
                            variant="outlined"
                            fullWidth={true}
                            value={state.fields.addressPostalCode.value}
                            error={state.shouldDisplayError && !state.fields.addressPostalCode.isValid}
                            helperText={state.shouldDisplayError && !state.fields.addressPostalCode.isValid && state.fields.addressPostalCode.errorMessage}
                            onTextChange={e => updateState('addressPostalCode', e)}
                        />
                    </Grid>

                    <div className="button-container">
                        <ButtonComponent onClick={updateCompanyDetails} isLoading={isLoading}>
                            Salveaza
                        </ButtonComponent>
                    </div>
                </Grid>
            </FormSectionComponent>
        </CompanySettingsComponentStyled>
    )

}

export default CompanySettingsComponent;