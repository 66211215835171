import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getCustomer } from "../../utils/requests";
import { useStore } from "../../hooks/store.hook";
import { AddShoppingCart, History, ListAlt} from "@mui/icons-material";
import { CustomerType } from "../../types/customer.type";
import CustomerDetailsHistoryTabComponent from "../customer-details-history-tab/customer-details-history-tab.component";
import OrderDetailsComponentStyled from "../order-details/order-details.component.styled";
import CustomerPanelComponent from "../customer-panel/customer-panel.component";
import { DataSourceEnum } from "../../enums/data-source.enum";
import OrderPanelComponent from "../order-panel/order-panel.component";
import { PanelType } from "../../enums/panel-type.enum";

export enum CustomerDetailsTab {
    CustomerDetails = 0,
    ActionLog = 1,
    CreateOrder = 2
}

export type CustomerDetailsComponentPropsType = {
    customerId: string;
    tabFocused?: CustomerDetailsTab;
    title?: string,
    source?: DataSourceEnum
}

const CustomerDetailsComponent = ({
    customerId,
    tabFocused,
    title,
    source
}:CustomerDetailsComponentPropsType) => {

    const uiStore = useStore('uiStore');
    const [customer, setCustomer] = useState<CustomerType>()
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [fetchAgain, setFetchAgain] = useState<boolean>(true)

    const fetchCustomer = (id: string) => {
        getCustomer(id)
            .then((customer: CustomerType) => {
                setCustomer(() => customer);
            })
            .catch((e: any) => {
                toast.error(e.message);
            })
    }

    useEffect(
        () => {
            if(fetchAgain === true){
                setFetchAgain(() => false)
                fetchCustomer(customerId)
            }
        },
        [customerId, fetchAgain]
    )

    useEffect(
        () => {
            
            uiStore.updatePanel({
                tabs: [
                    {
                        label: 'Detalii client',
                        icon: <ListAlt />
                    },
                    {
                        label: 'Istoric client',
                        icon: <History />,
                    },
                    {
                        label: 'Adauga comanda',
                        icon: <AddShoppingCart />
                    }
                ],
                tabIndex: tabIndex,
                onTabChange: (newValue) => setTabIndex(() => newValue),
            });
        },
        [tabIndex, uiStore]
    )

    useEffect(
        () => {
            if(!tabFocused) return
            setTabIndex( () => tabFocused)
        },
        [tabFocused]
    )

    useEffect(
        () => {
            uiStore.updatePanel({
                title: title ?? (customer?.firstName !== undefined && customer.fullName !== undefined ? customer?.fullName : customer?.email)
            })
        },
        [customer?.email, customer?.firstName, customer?.fullName, title, uiStore]
    )

    useEffect(
        () => {
            setFetchAgain(() => true)
        },
        [tabIndex]
    )

    useEffect(
        () => {
            if(!(tabIndex === CustomerDetailsTab.CreateOrder)) return

            uiStore.openPanel({
                key: PanelType.AddOrder,
                component: <OrderPanelComponent 
                    customerId={customerId} 
                    source={source} 
                />,
                panelWidth: '700px',
                title: 'Creeaza o comanda',
                onDismiss: () => {
                    setTabIndex( () => tabIndex-1)
                }
            });
        },
        [
            customerId, 
            tabIndex, 
            uiStore,
            source
        ]
    )
    /** define the return logic bellow */

    return (
        <OrderDetailsComponentStyled>

            {
                tabIndex === CustomerDetailsTab.CustomerDetails ?
                    <CustomerPanelComponent 
                        customerId={customer?.id} 
                        title={title} 
                        source={source} 
                    />
                    :
                    <></>
            }

            {
                tabIndex === CustomerDetailsTab.ActionLog ? 
                    <CustomerDetailsHistoryTabComponent
                        customerId={customer?.id}
                        source={source}
                    />
                    :
                    <></>
            }

        </OrderDetailsComponentStyled>
    )

}

export default CustomerDetailsComponent;