import styled from "styled-components";
import { themeVariables } from "../../theme/variables";
import { NAVBAR_ITEM_ICON_WIDTH, SIDE_NAVIGATION_EXPANDED_WIDTH, SIDE_NAVIGATION_RETRACTED_WITH, NAVBAR_ITEM_PADDING_HORIZONTAL, TOP_NAVBAR_HEIGHT } from "../../utils/constants";

export type SideNavigationComponentStyledPropsType = {
    isExpanded: boolean
}

const SideNavigationComponentStyled = styled.div<SideNavigationComponentStyledPropsType>`


    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    border-right: 1px solid lightgray;
    width: ${props => props.isExpanded ? SIDE_NAVIGATION_EXPANDED_WIDTH : SIDE_NAVIGATION_RETRACTED_WITH}rem;
    transition: width 250ms;
    z-index: 2;

    .MuiButtonBase-root-MuiButton-root{
        // min-width: 0 !important;
        width: 5rem !important;
    }

    .MuiButtonBase-root{
        // min-width: 0 !important;
        // width: 5rem !important;
        padding: 0.5 1rem;
        &:hover {
            background-color: #e5f3ee !important;
        }
    }

    .app-title {
        position: absolute;
        top: ${TOP_NAVBAR_HEIGHT/2}rem;
        left: calc(${SIDE_NAVIGATION_RETRACTED_WITH/8}rem - ${(NAVBAR_ITEM_ICON_WIDTH+NAVBAR_ITEM_PADDING_HORIZONTAL*2)/2}rem);
        transform: translateX(50%) translateY(-50%);
        color: ${themeVariables.colors.primary};
        font-weight: ${themeVariables.fontWeights.semibold};
        font-size: ${themeVariables.fontSizes.medium};
    }

    .toggle-navbar-button {
        position: absolute;
        top: ${TOP_NAVBAR_HEIGHT/2}rem;
        right: 0;
        transform: translateX(50%) translateY(-50%);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        background: white;
        border-radius: 50%;

        .MuiSvgIcon-root {
            transform: rotate(${props => props.isExpanded ? '180deg' : '0deg'});
            transition: all 500ms;
        }
    }

    .inner-content {
        left: 0;
        right: 0;
        position: absolute;
        top: 6.5rem;
        bottom: 1rem;
        overflow-y: scroll;
        overflow-x: hidden;

        ::-webkit-scrollbar {
            width: 12px !important;
        }

        ::-webkit-scrollbar {
            width: 12px !important;
        }
        
        ::-webkit-scrollbar-track {
            background-color: transparent;
        }
        
        ::-webkit-scrollbar-thumb {
            display: none;
        }
        
        ::-webkit-scrollbar-thumb:hover {
            background-color: #a8bbbf;
        }

        .navbar-tree-inner {
            position: absolute;
            left: calc(${SIDE_NAVIGATION_RETRACTED_WITH/2}rem - ${(NAVBAR_ITEM_ICON_WIDTH+NAVBAR_ITEM_PADDING_HORIZONTAL*2)/2}rem);
            right: calc(${SIDE_NAVIGATION_RETRACTED_WITH/2}rem - ${(NAVBAR_ITEM_ICON_WIDTH+NAVBAR_ITEM_PADDING_HORIZONTAL*2)/2}rem);
            top: 0;
            bottom: 0;

            .settings-item {
                margin-left: ${props => props.isExpanded ? '11px' : '8px'};;
                z-index: 2;
                width: ${props => props.isExpanded ? '14.8rem' : '1rem'};
                bottom: 0;
                position: fixed;
                left: 0;
                right: 0;
                transition: width 250ms;
            }

            .sidenav-divider {
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
            }

            .navbar-group {
                margin-bottom: 1.5rem;
            }

            
            .navbar-group-name {
                &.hide-group-name {
                    opacity: 0;
                    transition-property: opacity;
                    transition-duration: 125ms;
                }

                &.show-group-name {
                    opacity: 1;
                    transition-delay: 125ms;
                    transition-property: opacity;
                    transition-duration: 125ms;
                }
            }

            .navbar-group-items {
                .navbar-group-item {
                    margin-top: 0.2rem;
                    margin-bottom: 0.2rem;
                    width: 100%;
        
                    .navbar-group-item-header {
                    }
                }   
            }

        }

        
    }

    

    
`;

export default SideNavigationComponentStyled;