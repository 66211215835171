import styled from "styled-components";
import { themeVariables } from '../../theme/variables';

const CompanySettingsComponentStyled = styled.div`

    .button-container {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .section-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.2rem;

        .title2 {
            font-weight: ${themeVariables.fontWeights.semibold};
            font-size: ${themeVariables.fontSizes.smaller};
            text-transform: uppercase;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }
`;

export default CompanySettingsComponentStyled;