import { LinearProgress } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { centralRangeDateReq } from "../../utils/requests";
import CardNumericRaport from "../card-numeric-raport/card-numeric-raport.component";
import { ShowDataCardPropsType } from "../client-stats/client-stats.component";
import VirtualizedList from "../list-raport/list-raport.component";
import { RangeDateTypeProps } from "../overall-stats/overall-stats.component"

export type TelephoneExchangeComponentPropsType = {
  rangeDate: RangeDateTypeProps;
  tabIndex: number
}

export type CentralRangeDateReqPropsType = {
    answered: ShowDataCardPropsType,
    answeredPerAgent: ShowDataCardPropsType,
}

const TelephoneExchangeComponent = ({
  rangeDate,
  tabIndex
}: TelephoneExchangeComponentPropsType) => {

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showDataCard, setDataCard] = useState<CentralRangeDateReqPropsType>({
    answered: {
        label: 'Total comenzi preluate',
        value: 0
    },
    answeredPerAgent: {
        label: 'Total apeluri preluate / agent',
        value: {}
    }
  })

  useEffect(
    () => {
        async function loadingState() {
          setIsLoading(true)
          if(tabIndex === 1){
              const response = await centralRangeDateReq(rangeDate)
              setDataCard( 
                (fields) => ({
                    answered: {
                        ...fields.answered,
                        value: response.answered ?? '0'
                        // ? `${data.valNr.toFixed(2)} ${CurrencyEnum.RON}` : '0'
                    },
                    answeredPerAgent: {
                        ...fields.answeredPerAgent,
                        value: response.answeredPerAgent
                    },
                }))
          }
          setIsLoading(false)
        }

        loadingState()
    },
    [rangeDate, tabIndex]
  )

  const styleListTwo = useMemo(
    () => {
        return {
            width: 688,
            height: 407
        }
    },
    []
  )

  const styleBoxTwo = useMemo(
    () => {
        return {
            width: '43rem',
            height: '23.44rem'
        }
    },
    []
  )
    // var barChart = {
    //     title: 'Product Trends by Month',
    //     subtitle: '(+43%) than last year',
    //     series: [{
    //         data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
    //     }],
    //     options: {
    //         colors: [
    //             "#00ab55",
    //         ],
    //         chart : {
    //             width: '200px',
    //             toolbar: {
    //                 show: false,
    //             }
    //         },
    //         stroke: {
    //             width: 3,
    //         },
    //         plotOptions: {
    //             bar: {
    //                 borderRadius: 4,
    //                 horizontal: true,
    //                 barHeight: '20%',
    //             }
    //           },
    //           dataLabels: {
    //             enabled: false
    //           },
    //           xaxis: {
    //             categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
    //               'United States', 'China', 'Germany'
    //             ],
    //           },
    //           tooltip: {
    //             theme: 'dark',
    //             x: {
    //               show: false
    //             },
    //             y: {
    //               title: {
    //                 formatter: function () {
    //                   return ''
    //                 }
    //               }
    //             }
    //           }
    //     }
    // }
  
    return (
        <div>
          {
            isLoading ? 
                <LinearProgress style={{borderRadius: '10px'}} />
                :
                <div style={{
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    flexWrap: 'wrap', 
                }}>
                    <CardNumericRaport details={showDataCard.answered} />
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <VirtualizedList details={showDataCard.answeredPerAgent} styleList={styleListTwo} styleBox={styleBoxTwo} />
                    </div>
                </div>
        }
        </div>
    )
}

export default TelephoneExchangeComponent;